// https://azure.microsoft.com/pt-br/pricing/details/cognitive-services/speech-services/
import * as React from 'react';
import { Link } from 'gatsby';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Topbar from '../components/topbar';
import Layout from '../components/layout';

const headingAccentStyles = {
  color: '#663399',
};

const spaceTop = {
  marginTop: "130px"
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  faq: {
    marginTop: "50px"
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const tiers = [
  {
    title: 'Transcrição',
    price: '20',
    period: "hora",
    description: ['R$ 0,34 / minuto', 'Transcrição simultânea','Descontado por minuto', 'Tela de Administração', 'Suporte Eletrônico'],
    buttonText: 'Comprar',
    buttonVariant: 'outlined',
  },
  {
    title: 'Transcrição/Tradução',
    subheader: 'popular',
    price: '30',
    period: "hora",
    description: ['R$ 0,50 / minuto', 'Transcrição simultânea', 'Tradução simultânea', 'Descontado por minuto', 'Tela de Administração', 'Suporte Eletrônico'],
    buttonText: 'Comprar',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pacote Transcrição',
    price: '1000',
    description: ['R$ 0,21 / minuto', 'Transcrição simultânea', 'Pacote com 80 horas', 'Descontado por minuto', 'Tela de Administração', 'Suporte Eletrônico'],
    buttonText: 'Comprar',
    buttonVariant: 'outlined',
  },
];

const Preco = () => {
  const classes = useStyles(); 
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  return (
    <Layout>
      <Topbar />
  
      <title>Preços fixos e barato</title>
  
      <section style={spaceTop}>
        <Container maxWidth="md" component="main">

          <h1>
            Preços fixos e barato <span style={headingAccentStyles}>Pague conforme o uso</span>
          </h1>

          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        R$ {tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        { tier.period ? `/${tier.period}` : ""}
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} color="primary">
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          <h1 className={classes.faq}>
            Perguntas mais frequentes
          </h1>

          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>É possível fazer franscrição ao vivo</Typography>
          {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sim, tudo que você ouve na saída de som do seu computador será capturado e feito a transcrição simultânea
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Como faço para converter arquivos de audio em texto</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            You are currently not an owner
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Acesso o painel de administração e utilize a funcionalidade de conversão de audio para texto
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Quais idiomas são reconhecidos na transcrição?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mais de 80 idiomas são reconhecidos para fazer a transcrição. Acesso a página <Link to="/idiomas-traducao">idiomas tradução</Link> para ver a lista de idiomas suportados.
          </Typography>
        </AccordionDetails>
      </Accordion>

        </Container>

      
      </section>

    </Layout>
  )
};

export default Preco;
